import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgCollege from "../images/york-college.jpg"
import imgStaff from "../images/york-college-staff.jpg"
import imgVehicles from "../images/york-college-bus.jpg"
import imgStudentQRCode from "../images/york-college-student-mobile-bus-ticket.jpg"
import "./index.css";
import "./case-study.css";
import Nav from "../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Case study: ShuttleID credited with 15% growth in travel revenue for York College" />
        <Nav activeTab="case-studies" />
        <div>
            <div>

              <div className="case-study">

                <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                  <h1 className="blog-headline">Case study: ShuttleID credited with 15% growth in travel revenue for York College</h1>
                </div>

                <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                  <h1 className="blog-headline">Case study: ShuttleID credited with 15% growth in travel revenue for York College</h1>
                </div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>York College introduced smart ticket technology to plug leaking revenue streams, gain full control of sales, and grow bus pass income by 15%. Switching from outdated paper tickets to precision scanning proved positive for students, parents and the college.</p>
                    <p>As with any college, a tight control of finances, transparency and accountability is vital. But by 2022, Student Experience Officer (Transport) Jo Harrison had exhausted every option for generating student travel passes in-house. She put her fear of cost and a complex integration to one side, and was wowed with what she discovered...</p>
                  </div>
                  <div className='article-inner'>
                    <div className='case-study__metrics'>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>608,000</span>
                        journeys protected annually
                      </div>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>15%</span>
                        revenue increase
                      </div>
                    </div>
                    <div className='case-study__about'>
                      <h3>A modern college serving a big community</h3>

                      <p style={{ marginBottom: '1em' }}>Over 7000 students attend York College each year and more than 1600 of those use the college’s 15 bus routes to get to or from class.  From A-levels to vocational training, degree courses to adult learners, the college reaches a broad cross-section of society and attracts students from over 65 square miles of Yorkshire.</p>
                      <p style={{ marginBottom: '1em' }}>The College campus spans 10 acres to the west of York city centre. Its location makes it easily accessible from major arterial roads and its iconic £60m facilities puts it as one of the country’s most sought-after educational establishments.</p>
                      <p>
                        <img style={{ marginBottom: 0 }} src={imgCollege} alt="York College campus" />
                        <span><em>York College campus</em></span>
                      </p>
                    </div>

                    <h2>Profit and loss</h2>
                    <p>The history of York College goes back to 1827. Its success is built on an ethos of ambitious forward-thinking. And it was this mindset that, in 2022, averted a financial blow as the college began losing money from its home-to-college transport ticket sales.</p>
                    <p>Issuing tickets for student travel has long been a difficult assignment for many colleges; managing multiple transport partners, a web of bus routes, and a variety of ticket types can be puzzling even when things run smoothly.</p>
                    <p>“We used to sell paper tickets from the finance desk,” Jo said. “But it caused massive queues, and I realised quickly that the process was old-fashioned. We needed to go online.” </p>
                    <p>
                      <img style={{ marginBottom: 0 }} src={imgStaff} alt="York College student experience team" />
                      <span><em>Joanne Harrison (Student Experience Officer) and Eleanor Bennett (Student Experience Manager)</em></span>
                    </p>
                    <p>Education budgets were already stretched however, and the cost of a creating a bespoke system to scan tickets was out of the question, so the College looked for other in-house solutions.  One idea involved a clever use of PDF tickets bought through the College’s online shop. It meant that passes could be emailed to students for printing at home. Whilst a PDF could not be scanned, the system saved big queues at the desk and sizeable postage costs.</p>

                    {/* <p>
                      <img style={{ marginBottom: 0 }} src={imgCaseStudy} alt="Flagfinders team" />
                      <span><em>The Flagfinders team: Wendy, Lesley and Trish</em></span>
                    </p> */}

                    <h2>Lack of transparency</h2>
                    <p>The problem was surely cracked! Then Covid hit. “We never got a true picture of what sales looked like because fewer students were travelling,” said Jo. In 2021 when students returned, it was clear that the PDF method was fallible: print-at-home tickets lacked traceability, were environmentally unsound and, as it turned out, ineffective for bus drivers.</p>
                    <p>"PDF tickets proved unmanageable for our bus operators,” said Jo. “Drivers didn’t have time to check each ticket with queues of students trying to board.”</p>
                    <p>Tickets were often lost, forgotten or illegible, and there was no way of checking who should have been on each bus. And with vulnerable students amongst the throngs, it was unthinkable to refuse travel. Jo even asked teaching staff to authenticate passes as students disembarked at college. But it was fruitless; the extra checks were time consuming, and printouts couldn’t be traced to purchase.</p>

                    <h2>Partner recommendation</h2>
                    <p>Jo acknowledged that she needed to accelerate her idea for a ticket-scanning system: a system that was fraud proof, fool proof and flexible enough to grow with the College’s demands.</p>
                    <p>“Over time, we had several providers in to talk about their ticket solutions, but they were too expensive,” said Jo. “I also looked at what other colleges were doing transport-wise. Then, having seen it in place elsewhere, one of my bus partners recommended ShuttleID.”</p>
                    <p>For the finance team, cost was a crucial factor. However, Jo found it easy to reason when she saw how much income a good system would generate, far more than the College was already losing. For Jo, reliability, smooth integration and a system that was easy for parents and students to use, was equally as important as cost.</p>
                    <p>
                      <img style={{ marginBottom: 0 }} src={imgVehicles} alt="York College campus" />
                      <span><em>York Pullman Bus Company; one of York College's partner operators</em></span>
                    </p>

                    <h2>Simple integration</h2>
                    <p>Jo said, “With our previous ticket ideas, several departments in the college were pulled into the process, but ShuttleID has consolidated the work into one role. My job has changed completely, and our finance team is delighted on account of more funds and fewer headaches!</p>
                    <p>“Everything is done online in an easy-to-use platform, from auto renewals and payment reminders to issuing replacement passes in an instant. It has been hugely beneficial for students and parents."</p>
                    <p>“Integrating ShuttleID was straightforward too, even considering the many routes, zones and stops that our buses cover. I was a little daunted by the prospect, but setup was done quickly, and it all went smoothly. Plus, the training materials we received from ShuttleID were good."</p>
                    <p>“I thought the drivers would be a bit apprehensive, but they soon learned they just need to press a button. It has taken the stress out of their day, leaving them free to focus on driving safely.” </p>
                    <p><img src={imgStudentQRCode} alt="Student using QR code" /></p>

                    <h2>How ShuttleID works for York College</h2>
                    <p>The online platform allows students and parents to buy tickets with secure payment. Tickets are delivered straight to a nominated phone. Students scan their ticket barcode as they board, and the system validates the ticket for travel. Jo commented, “Parent reminders can be triggered for top-up payments, alerts can be sent for travel disruption and users can track the bus location – all of which has reduced phone calls into the office.”</p>
                    <p>The additional benefit of being able to track who boards, when they board and where has improved safeguarding and behaviour monitoring. Jo said, “What’s more, I’m really happy that ShuttleID can make bespoke changes to the system for us too. ShuttleID is keen to work with clients to make real improvements and offer solutions.”</p>

                    <h2>15% increase in ticket revenue</h2>
                    <p>Jo concluded, “The feedback I've had from drivers is excellent; they've found ShuttleID easy to work with. Every morning, I come in and check the tracking to make sure drivers are logged in and scanning tickets."</p>
                    <p>“In the first year of use, our ticket revenue increased 15% and we’re still seeing uplifts. What’s impressive is that our finance team has saved at least one day a week. Unsurprisingly, I've received no complaints! Everyone is getting on with it and I am singing ShuttleID’s praises."</p>

                    <p><Link to="/book-demo/" className="btn">Book a demo</Link></p>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
